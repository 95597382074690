import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const ImageUploadModal = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('original');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleFileUpload = (acceptedFiles) => {
    setLoading(true);
    // Create a FormData
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    formData.append('style', value);

    fetch('/api/avatar/upload', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      },
      body: formData
    })
    .then(response => {
      if (response.status !== 200) {
        throw new Error('Request failed with status ' + response.status);
      }
      
      return response.json()
    })
    .then(data => {
      props.callback()
      handleClose()
    })
    .catch(err => {
      console.error(err)
    })
    .finally(() => setLoading(false))
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: handleFileUpload,
  });

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Image Upload</DialogTitle>
        {
          loading ? (
            <DialogContent sx={{ height: 680, width: 500 }}>
              <CircularProgress sx={{width: 150, height: 150}}/>
            </DialogContent>
          ) : (
            <DialogContent sx={{ height: 680, width: 500, textAlign: "center" }}>
              <img 
                src="https://acemeta-ai-demo.s3.ap-southeast-1.amazonaws.com/selfie_example.jpg" 
                alt="Example Selfie"
                style={{ width: '50%', marginBottom: '10px' }} 
              />
              <p>Example Selfie</p>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Avatar Styles</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={event => setValue(event.target.value)}
                >
                  <FormControlLabel value="original" control={<Radio />} label="original" />
                </RadioGroup>
              </FormControl>
              <div {...getRootProps()} 
                style={{ 
                  cursor: 'pointer',
                  border: 'dashed 1px #012eff', 
                  padding: '30px 5px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <input {...getInputProps()} />
                <AddIcon sx={{width: 100, height: 100, color: '#012eff'}}/>
                <Typography sx={{color: '#012eff'}}>
                  Drag and drop an image here or click to select a file.
                </Typography>
              </div>
            </DialogContent>
          )
        }
        
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default ImageUploadModal;
