import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/CloudUpload';
import MicRecorder from 'mic-recorder-to-mp3';
import MicIcon from '@mui/icons-material/Mic';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import CircularProgress from '@mui/material/CircularProgress';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const SoundUploadModal = React.forwardRef((props, ref) => {
  const inspirationalText = "The only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. Passion fuels the fire of your soul, and with it, you can achieve unimaginable heights. Believe in yourself and your dreams. Embrace the journey, for it's filled with challenges and opportunities that shape who you are. Never give up on what matters to you, and never be afraid to take the path less traveled. Seek inspiration in every step, and remember that failures are merely lessons. Your dedication, courage, and love for what you do will lead you to greatness. Stand tall, stay focused, and the world will be yours."; 

  const [open, setOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textToShow, setTextToShow] = useState(""); 
  const [recordingDialogHeight, setRecordingDialogHeight] = useState('320px'); 

  const handleOpen = () => {
    setOpen(true);
  };

  React.useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = (acceptedFiles) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    submit(formData)
  }

  const submit = (formData) => {
    setLoading(true);
    fetch('/api/voice/upload', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      },
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          return response.json().then(err => { throw new Error(err.msg) });
        }

        return response.json()
      })
      .then(data => {
        props.callback()
        handleClose()
      })
      .catch(err => {
        console.error(err)
        alert(err.message)
      })
      .finally(() => setLoading(false))
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'audio/*',
    multiple: false,
    onDrop: handleFileUpload,
  });

  const startRecording = async () => {
    try {
      setTextToShow(inspirationalText); 
      setRecordingDialogHeight('600px');
      await Mp3Recorder.start();
      setIsRecording(true);
    } catch (err) {
      console.error(err);
    }
  };

  const stopRecording = async () => {
    try {
      await Mp3Recorder.stop().getMp3().then(async ([buffer, blob]) => {
        const formData = new FormData();
        formData.append("file", blob, "audio.mp3");
        setIsRecording(false);
        submit(formData);
        setRecordingDialogHeight('320px');
        setTextToShow(""); 
      });
    } catch (err) {
      console.error(err);
    }
  };

  const DashedBox = ({ children }) => {
    return (
      <div
        style={{
          cursor: 'pointer',
          border: 'dashed 1px #012eff',
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Sound Upload Or Record</DialogTitle>
        {
          loading ? (
            <DialogContent sx={{ height: 250, width: 500 }}>
              <CircularProgress sx={{ width: 150, height: 150 }} />
            </DialogContent>
          ) : (
            <DialogContent sx={{ height: recordingDialogHeight, width: 500 }}>
              <div {...getRootProps()}
                style={{
                  cursor: 'pointer',
                  border: 'dashed 1px #012eff',
                  padding: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <input {...getInputProps()} />
                <AddIcon color="primary" sx={{ width: 100, height: 100 }} />
                <Typography color="primary">
                  Drag and drop an voice here or click to select a file.
                </Typography>
              </div>
              <Divider sx={{ margin: '10px 0' }}>OR</Divider>
              <DashedBox>
                {
                  isRecording
                    ? <StopCircleIcon color="primary" sx={{ width: 100, height: 100 }} onClick={stopRecording} />
                    : <MicIcon color="primary" sx={{ width: 100, height: 100 }} onClick={startRecording} />
                }

                <Typography color="primary">
                  {isRecording ? 'Click to stop recording' : 'Click to start recording'}
                </Typography>
              </DashedBox>
              {
                isRecording && ( 
                  <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
                    {textToShow}
                  </Typography>
                )
              }

            </DialogContent>
          )}
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default SoundUploadModal;
