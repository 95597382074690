import React from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const SoundPlayer = ({url}) => {
  const audioRef = React.useRef(null);

  const playSound = () => {
    audioRef.current.play();
  };

  return (
    <div>
      <audio ref={audioRef} src={url} />
      <PlayCircleIcon color="primary" fontSize="large" onClick={playSound} />
    </div>
  );
};

export default SoundPlayer;
