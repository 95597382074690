import React, { useState } from 'react';
import { Button, Modal, TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';

const AudioGenerationModal = React.forwardRef(({ voiceList, callback }, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [voiceValue, setVoiceValue] = useState('');
  const [textInputValue, setTextInputValue] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleSelectVoiceChange = (event) => {
    setVoiceValue(event.target.value);
  };

  const handleTextInputChange = (event) => {
    setTextInputValue(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    fetch('/api/audio', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      },
      body: JSON.stringify({
        voiceId: voiceValue,
        text: textInputValue
      })
    }).then(response => {
        if (response.status !== 200) {
          throw new Error('Request failed with status ' + response.status);
        }
        
        return response.json()
      }).then(data => {
        callback()
        handleClose()
      }).catch(err => {
        console.error(err)
      }).finally(() => setLoading(false))
    };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <FormControl fullWidth sx={{marginTop: 3}}>
          <InputLabel>Choose Voice</InputLabel>
          <Select value={voiceValue} onChange={handleSelectVoiceChange}>
            {
              voiceList.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Text Input"
          value={textInputValue}
          onChange={handleTextInputChange}
          sx={{marginTop: 3}}
        />
        <div style={{textAlign: 'center'}}>
          <Button onClick={handleSubmit} variant="contained" color="primary" sx={{marginTop: 3}} disabled={loading}>
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
});

export default AudioGenerationModal;
