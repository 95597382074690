import React, { useState } from 'react';
import { Button, Modal, TextField, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';

const ModalForm = React.forwardRef(({ imgList, voiceList, callback }, ref) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [imageValue, setImageValueValue] = useState('');
  const [voiceValue, setVoiceValue] = useState('');
  const [textInputValue, setTextInputValue] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  const handleSelect1Change = (event) => {
    setImageValueValue(event.target.value);
  };

  const handleSelect2Change = (event) => {
    setVoiceValue(event.target.value);
  };

  const handleTextInputChange = (event) => {
    setTextInputValue(event.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    fetch('/api/talkingAvatar/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      },
      body: JSON.stringify({
        avatarId: imageValue,
        voiceId: voiceValue,
        text: textInputValue
      })
    }).then(response => {
      if (response.status !== 200) {
        throw new Error('Request failed with status ' + response.status);
      }
      
      return response.json()
    }).then(data => {
      callback()
      handleClose()
    }).catch(err => {
      console.error(err)
    }).finally(() => setLoading(false))
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <FormControl fullWidth sx={{marginTop: 3}}>
          <InputLabel>Choose Image</InputLabel>
          <Select value={imageValue} onChange={handleSelect1Change}>
            {
              imgList.map((item, index) => (
                item.status === 'completed' ? <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem> : null
              ))
            }
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{marginTop: 3}}>
          <InputLabel>Choose Voice</InputLabel>
          <Select value={voiceValue} onChange={handleSelect2Change}>
            {
              voiceList.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Text Input"
          value={textInputValue}
          onChange={handleTextInputChange}
          sx={{marginTop: 3}}
        />
        <div style={{textAlign: 'center'}}>
          <Button onClick={handleSubmit} variant="contained" color="primary" sx={{marginTop: 3}} loading={loading}>
            Submit
          </Button>
        </div>
      </Box>
    </Modal>
  );
});

export default ModalForm;
