import React, {useRef, useEffect, useState} from "react";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import SoundPlayer from "../components/SoundPlay";
import ImageUploadModal from '../components/ImageUploadModal';
import SoundUploadModal from '../components/SoundUploadModal';
import TalkingGenerateModal from '../components/TalkingGenerateModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';
import SyncIcon from '@mui/icons-material/Sync';
import Dialog from '@mui/material/Dialog';
import AudioGenerationModal from "../components/AudioGenerateModal";

export default function AI() {
  const imgRef = useRef(null);
  const soundRef = useRef(null);
  const talkingRef = useRef(null);
  const audioRef = useRef(null);

  const [imgList, setImgList] = useState([]);
  const [voiceList, setVoinceList] = useState([]);
  const [avatarList, setAvatarList] = useState([]);
  const [audioList, setAudioList] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const getWrap = (url, callback) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      }
    }).then(response => {
      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      if (response.status !== 200) {
        throw new Error('Request failed with status ' + response.status);
      }
      
      return response.json()
    }).then(data => {
      callback(data)
    }).catch(err => {
      console.error(err)
    })
  }

  const deleteWrap = (url, callback) => {
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      }
    }).then(response => {
      if (response.status !== 200) {
        throw new Error('Request failed with status ' + response.status);
      }
      
      return response.json()
    }).then(data => {
      callback()
    }).catch(err => {
      console.error(err)
    })
  }

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getImages = () => {
    getWrap('/api/avatars', setImgList)
  }

  const getVoices = () => {
    getWrap('/api/voices', setVoinceList)
  }

  const getTalkings = () => {
    getWrap('/api/talkingAvatars', setAvatarList)
  }

  const getAudios = () => {
    getWrap('/api/audios', setAudioList)
  }

  const deleteVoice = (id) => {
    deleteWrap(`/api/voice/${id}`, getVoices)
  }

  const deleteAudio = (id) => {
    deleteWrap(`/api/audio/${id}`, getAudios)
  }

  useEffect(() => {
    getImages()
    getVoices()
    getTalkings()
    getAudios()
  }, []);

  const Heading = ({ children, add, sync }) => {
    return (
      <div style={{
        backgroundColor: "#012eff",
        color: "#fff",
        padding: 10,
        fontSize: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}>
        {children}
        <div style={{display: 'flex', alignItems: 'center'}}>
          <AddBoxOutlinedIcon fontSize="large" sx={{cursor: 'pointer'}} onClick={add} />
          <SyncIcon fontSize="large" sx={{cursor: 'pointer', marginLeft: 1}} onClick={sync} />
        </div>
      </div>
    )
  }

  const Listing = ({ children }) => {
    return (
      <div style={{
        padding: "30px 20px 50px 20px",
        display: "flex",
        flexWrap: "wrap",
      }}>
        {children}
      </div>
    )
  }

  return (
    <div className="container">
      <Heading add={() => imgRef.current.open()} sync={getImages}>
        Basic APP 1: Upload Selfie To Create Avatar(only support human face)
      </Heading>

      <Listing>
        {
          imgList.map(item => (
            <Card sx={{ display: 'flex', marginRight: 4 }} key={item.id}>
              <Typography variant="body1" color="textSecondary" sx={{ margin: 2 }}>
                {item.id}
              </Typography>
              {
                item.status === 'loading' ? (
                  <CircularProgress color="primary" sx={{ margin: 5, width: 50, height: 50 }} />
                ) : item.status === 'completed' ? (
                  <CardMedia
                    component="img"
                    sx={{ height: 200, cursor: 'pointer' }}
                    image={item.fileURI}
                    alt=""
                    onClick={() => handleClickOpen(item.fileURI)}
                  />
                ) : item.status === 'failed' ? (
                  <Typography variant="body1" color="textSecondary" sx={{ margin: 5, width: 60, }}>
                    Human face not detected
                  </Typography>
                ) : null
              }
            </Card>
          ))
        }
      </Listing>

      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <img src={selectedImage} alt="" style={{ width: '100%', maxHeight: '95vh' }} />
      </Dialog>


      <Heading add={() => soundRef.current.open()} sync={getVoices}>
        Basic APP 2: Upload Or Record To Clone Your Voice(1 minute in English required, delete current voice to create a new one)
      </Heading>

      <Listing>
          {
            voiceList.map(item => (
              <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }} key={item.id}>
                <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component="div" variant="h5">
                    Voice {item.id}
                  </Typography>
                  <Box sx={{marginLeft: 3}}>
                    <SoundPlayer url={item.sampleURI} />
                  </Box>
                </CardContent>
                <CardActions>
                  <DeleteForeverIcon color="error" fontSize="large" onClick={() => deleteVoice(item.id)}/>
                </CardActions>
              </Card>
            ))
          }
      </Listing>

      <Heading add={() => talkingRef.current.open()} sync={getTalkings}>
        SCENARIO 1: Generate Talking Avaters(requires avatar and voice in APP 1 and 2)
      </Heading>


      <Listing>
        {
          avatarList.map(item => (
            <Card sx={{ display: 'flex', marginRight: 4 }} key={item.id}>
            {
              item.status !== 'completed' ? (
                <CircularProgress color="primary" sx={{margin: 5, width: 50, height: 50}} />
              ) : (
                <CardMedia
                  component="video"
                  controls
                  sx={{ height: 200 }}
                  image={item.fileURI}
                  alt=""
                />
              )}
            </Card>
          ))
        }
      </Listing>

           
      <Heading add={() => audioRef.current.open()} sync={getAudios}>
        SCENARIO 2: Custom audios(like a birthday wish), cloning voice is required
      </Heading>

      <Listing>
        {
          audioList.map(item => (
            <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }} key={item.id}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography component="div" variant="h5">
                  Audio {item.id} (Voice {item.voice_id})
                </Typography>
                <Box sx={{ marginLeft: 3 }}>
                  <SoundPlayer url={item.audio_uri} />
                </Box>
                <Box sx={{ marginLeft: 3, flex: 1 }}>
                  <Typography variant="body1">
                    Text: {item.text}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions>
                <DeleteForeverIcon color="error" fontSize="large" onClick={() => deleteAudio(item.id)} />
              </CardActions>
            </Card>
          ))
        }
      </Listing>


      <ImageUploadModal ref={imgRef} callback={getImages} />
      <SoundUploadModal ref={soundRef} callback={getVoices} />
      <TalkingGenerateModal ref={talkingRef} callback={getTalkings} imgList={imgList} voiceList={voiceList} />
      <AudioGenerationModal ref={audioRef} callback={getAudios} voiceList={voiceList} />
    </div>
  )
}