import Signin from "./Signin";
import AI from "./AI";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#012eff', // Replace with your desired primary color
    },
  },
});

const App = function () {
    const token = localStorage.getItem("token");

    return (
        <ThemeProvider theme={theme}>
            {token ? <AI /> : <Signin />}
        </ThemeProvider>
    )
}

export default App